import {
  DomainFilterConfig,
  SentryEventFiltersConfig,
  MessageFilterConfig,
  ModuleFilterConfig,
} from '@breathelife/types';
import { LeakSensor } from '@breathelife/monitoring-common';

import { EventModifier, BeforeSend } from '../types';
import { messageFilter, domainFilter, moduleFilter } from './';
import { sanitize } from './sanitize';

function createDomainFilter(domainFilterConfig?: DomainFilterConfig[]): EventModifier {
  if (domainFilterConfig === undefined) {
    return () => undefined;
  }

  return (event) => {
    return domainFilter(event, domainFilterConfig);
  };
}

function createMessageFilter(messageFilterConfig?: MessageFilterConfig[]): EventModifier {
  if (messageFilterConfig === undefined) {
    return () => undefined;
  }

  return (_event, hint) => {
    return messageFilter(hint, messageFilterConfig);
  };
}

function createModuleFilter(messageFilterConfig?: ModuleFilterConfig[]): EventModifier {
  if (messageFilterConfig === undefined) {
    return () => undefined;
  }

  return (event) => {
    return moduleFilter(event, messageFilterConfig);
  };
}

export function createBeforeSend(
  sentryEventFilters: SentryEventFiltersConfig,
  leakSensor: LeakSensor,
  getIsLeakSensorEnabled: () => boolean,
): BeforeSend {
  const domainFilter = createDomainFilter(sentryEventFilters.domains);
  const messageFilter = createMessageFilter(sentryEventFilters.messages);
  const moduleFilter = createModuleFilter(sentryEventFilters.modules);

  const filters = [domainFilter, messageFilter, moduleFilter];

  return function beforeSend(event, hint) {
    const isLeakSensorEnabled = getIsLeakSensorEnabled();
    for (const filter of filters) {
      const eventModifier = filter(event, hint);

      if (eventModifier !== undefined) {
        event.fingerprint = [eventModifier.fingerprint];
        event.level = eventModifier.severity;

        return isLeakSensorEnabled ? sanitize(event, leakSensor) : event;
      }
    }

    return isLeakSensorEnabled ? sanitize(event, leakSensor) : event;
  };
}
