import { AxiosError } from 'axios';

export function buildMonitoringInterceptor(
  warningCallback: (error: AxiosError) => void,
  errorCallback: (error: AxiosError) => void,
): (error: AxiosError) => Promise<AxiosError> {
  return function monitoringInterceptor(error: AxiosError): Promise<AxiosError> {
    const statusCode = Number(error.code || error?.response?.status);
    const isWarning = [404, 409].includes(Number(statusCode));

    // Axios is throwing a Request Aborted error message on request.onabort callback.
    // See request.onabort https://github.com/axios/axios/blob/master/lib/adapters/xhr.js
    // Handle browser request cancellation (as opposed to a manual cancellation)
    const isRequestAbortedByBrowser = error.message === 'Request aborted' && !!error.isAxiosError;
    if (isRequestAbortedByBrowser) return Promise.reject(error);

    if (isWarning) {
      warningCallback(error);
    }

    // Removing to avoid data leaks in AxiosErrors reported
    if (error.config?.data) {
      delete error.config.data;
    }

    errorCallback(error);

    return Promise.reject(error);
  };
}
