import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Middleware } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'semantic-ui-css/semantic.min.css';

import config from '@breathelife/config/frontend';
import { consumerFlowStore } from '@breathelife/consumer-flow';
import { leadPlatformStore } from '@breathelife/lead-platform';
import { initMonitoring } from '@breathelife/monitoring-frontend';
import { ExternalScriptDomElementPosition } from '@breathelife/react-hooks';
import { Language } from '@breathelife/types';
import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';
import {
  ErrorBoundary,
  GlobalAppErrorPage,
  ExternalUrlScriptLoader,
  setTranslations,
  textIfExists,
} from '@breathelife/ui-components';

import initializeReduxStore from './Redux/Store';
import { Root } from './Root';

const featureFlags = featureFlag.init(config.get('featureFlags'));
// eslint-disable-next-line no-console
console.info(`Feature flags initialized`, featureFlags);

const carrierTranslations = config.get<Partial<Record<Language, any>>>('localization.global');

if (!config.isLoaded) {
  renderError();
}

initMonitoring(config.get('carrier.id'), config.get('monitoring'), () => {
  return featureFlag.get(FeatureFlag.enableLoggingLeakSensor);
});

setTranslations(carrierTranslations);

const rootReducer = consumerFlowStore.RootReducer;
const consumerFlowMiddlewares = consumerFlowStore.middlewares();
const leadPlatformMiddlewares = leadPlatformStore.middlewares();
const middlewares = [...consumerFlowMiddlewares, ...leadPlatformMiddlewares];

const { store, persistor } = initializeReduxStore({ ...rootReducer }, middlewares as Middleware[]);

const segmentKey = config.get('keys.segment');

ReactDOM.render(
  <Fragment>
    <ExternalUrlScriptLoader
      script={{
        id: 'segment',
        domElementPosition: ExternalScriptDomElementPosition.head,
        attributes: {
          src: `https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`,
          async: true,
        },
      }}
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary textIfExists={textIfExists}>
          <Root />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </Fragment>,
  document.getElementById('root'),
);

function renderError(): void {
  const DEFAULT_LANG = Language.en;

  const supportedLanguage = [Language.en, Language.fr];

  const navigatorLanguage = window.navigator?.language.split('-')[0];

  const currentNavigatorLanguage = supportedLanguage.includes(navigatorLanguage as Language)
    ? (navigatorLanguage as Language)
    : DEFAULT_LANG;

  const currentLocale = (window.localStorage.getItem('locale') as Language) || currentNavigatorLanguage;

  ReactDOM.render(
    <GlobalAppErrorPage
      line1={carrierTranslations[currentLocale].globalErrorPage.line1}
      line2={carrierTranslations[currentLocale].globalErrorPage.line2}
    />,
    document.getElementById('root'),
  );
}
